import { useNavigate, useSearchParams } from "react-router-dom";

const EnrollButtons = (props: any) => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const first_name = String(searchParams.get("first_name"));
  const last_name = String(searchParams.get("last_name"));
  const zipcode = String(searchParams.get("zipcode"));
  const city = String(searchParams.get("city"));
  const state = String(searchParams.get("state"));
  const address = String(searchParams.get("address"));
  const notes = String(searchParams.get("notes"));
  const email = String(searchParams.get("email"));

  const handleClickYes = async (quote_id: string, premium: string) => {
    console.log("quote_ID: ", quote_id);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        endpoint: "credit/policy/enroll?quote_id=" + quote_id,
        payload: {
          first_name: first_name,
          last_name: last_name,
          zipcode: zipcode,
          city: city,
          state: state,
          address: address,
          notes: notes,
          email: email,
        }
      })
    };
    const response = await fetch("https://dp4zzfjw4m.us-east-2.awsapprunner.com/post", requestOptions);
    if (response.status === 202) {
      const data = await response.json();
      navigate("/confirm-enrollment", {
        state: { policy_id: data.policy_id, premium: premium },
      })
    } else {
      console.log(response.status);
    }

  }
  const handleClickNo = (quote_id: string, premium: string) => {
    navigate("/confirm-skip-enrollment")
  }

  return (
    <div className="button-row-container">
      <div className="button-single-container">
        <button className="enroll-button" onClick={() => { handleClickYes(props.quote_id, props.premium) }}>
          Enroll me now
        </button>
      </div>
      <div className="button-single-container">
        <button className="refuse-button" onClick={() => { handleClickNo(props.quote_id, props.premium) }}>
          No, thank you
        </button>
      </div>
    </div>
  );
}

export default EnrollButtons;
