import React from "react";
import logo from "./assets/Flap_word_Logo.png"

const Footnote = () => {
  return (
    <div className="footnote">
      <span className="footnote-text">
        Powered by<img className="footnote-logo" src={logo} alt="logo" />
      </span>
    </div>
  );
};

export default Footnote;
