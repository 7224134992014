import { BrowserRouter, Routes, Route } from "react-router-dom";
import Coverage from "./Coverage";
import EnrollButtons from "./EnrollButtons";
import Disclosure from "./Disclosure";
import Footnote from "./Footnote";
import GetQuote from "./GetQuote";
import NotFound from "./NotFound";
import Smoker from "./Smoker";
import PremiumInfo from "./PremiumInfo";
import { Component } from "react";
import ConfirmationYes from "./ConfirmationYes";
import ConfirmationNo from "./ConfirmationNo";


class App extends Component<{},{quote_id: string, premium: string}> {

  constructor(props: any) { 
    super(props); 
    this.handleQuoteID = this.handleQuoteID.bind(this); 
    this.state = {quote_id: '', premium: ""}; 
  } 

  handleQuoteID(quote_id: string, premium: string) { 
    this.setState({quote_id: quote_id, premium: premium}, ()=> {
      console.log("App: quote_id: ", quote_id, "Premium", premium);
      this.readQuoteID();
    });
  }

  readQuoteID() {
    return this.state.quote_id;
  } 

  render() {
    return (
      <div className="App">
        <div className="content">
          <div className="content-box">
            <BrowserRouter>
              <Routes>
                <Route
                  path="add-protection"
                  element={
                    <>
                      <PremiumInfo handleQuoteID={this.handleQuoteID}/>
                      <Coverage />
                      <Disclosure />
                      <EnrollButtons quote_id={this.state.quote_id} premium={this.state.premium}/>
                    </>
                  }
                />
                <Route path="/confirm-enrollment" element={<ConfirmationYes />} />
                <Route path="/confirm-skip-enrollment" element={<ConfirmationNo />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </div>
          <Footnote />
        </div>
      </div>
    );
  }
}

export default App;
