import info_icon from "./assets/info-symbol.png";

const Coverage = () => {
  return (
    <div className="flap-coverage">
      <div className="coverage-header-container">
        <div className="text-large"> covers your non-payment due to</div>
      </div>
      <div className="bullet-box"><p className="bullet">✓<span className="text-bullet">Involuntary unemployment</span></p></div>
      <br />
      <div className="bullet-box"><p className="bullet">✓<span className="text-bullet">Disability</span></p></div>
      <br />
      <div className="bullet-box"><p className="bullet">✓<span className="text-bullet">Accidental death</span></p></div>
      <br />
      <div className="bullet-box"><p className="bullet">✓<span className="text-bullet">Critical illness</span></p></div>
      <br />
    </div>
  );
};

export default Coverage;
