import { useLocation } from "react-router-dom";
import CheckPNG from "./assets/green_check_tiny.png";

const ConfirmationNo = () => {
    let { state } = useLocation();

    return (
        <div className="flap-confirmation">
            <div className="confirm-skip-header-container">
                <span>
                <img className="imgtxt" src={CheckPNG} alt="check" />
                <div className="text-large">Thank you!</div>
                </span>
            </div>

            <div className="text-bottom-box-skip">
            <div className="text-medium-skip">
            We have sent you an email of your response. You can still enroll in credit insurance within 7 days of your loan activation. If you have any questions about the coverage, please feel free to contact us at support@flapinsurance.com
            </div>
            </div>
        </div>
    );
};

export default ConfirmationNo;