import React from "react";

const Disclosure = () => {
  return (
    <div className="flap-disclosure">
      <div className="text-medium">
        Disclosure: by submitting my options below, I confirm that I understand the terms, conditions, exclusions in <a href="https://www.flapinsurance.com" target="_blank">FAQs and Disclosures</a>.
      </div>
    </div>

  );
};

export default Disclosure;
