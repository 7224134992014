import { useState, useEffect, Component } from "react";
import { useSearchParams } from "react-router-dom";
import shield from "./assets/Shield.png";

class PremiumInfoComponent extends Component<{ quote_id: string, premium: string }, {}> {
  render() {
    return (
      <div className="flap-quote-container">
        <div className="header-container">
          <img className="flap-quote-imgtxt" src={shield} alt="shield" />
          <div className="flap-quote-text">Protect your loan for ${this.props.premium}/month!</div>
        </div>

      </div>
    );
  }
};

const PremiumInfo = (props: any) => {
  const [searchParams] = useSearchParams();
  const partnerid = searchParams.get("partner_id");
  const balance = Number(searchParams.get("balance"));
  const rate = Number(searchParams.get("rate"));
  const payment = Number(searchParams.get("payment"));
  const product = String(searchParams.get("product"));
  const birth_date = String(searchParams.get("birth_date"));
  const expiry_date = String(searchParams.get("expiry_date"));
  const gender = String(searchParams.get("gender"));
  const lender = String(searchParams.get("lender"));

  //const { amount, age } = useParams();
  const [prem, setPrem] = useState("");
  const [quote_id, setQuoteID] = useState("");

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      endpoint: "credit/policy/quote",
      payload: {
        balance: balance,
        payment: payment,
        rate: rate,
        product: product,
        birth_date: birth_date,
        expiry_date: expiry_date,
        condition: "none",
        gender: "male",
        partner_id: partnerid,
        lender: lender,
      }
    })
  };

  const fetchPremium = async () => {
    try {
      const response = await fetch("https://dp4zzfjw4m.us-east-2.awsapprunner.com/post", requestOptions);
      if (response.status === 202) {
        const data = await response.json();
        setPrem(data.premium_amount);
        setQuoteID(data.quote_id);
        props.handleQuoteID(data.quote_id, data.premium_amount);
      } else {
        console.log(response.status);
        console.log(response.body);
      }

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!quote_id) {
      fetchPremium();
    }
  }, []);

  return <PremiumInfoComponent quote_id={quote_id} premium={prem} />
};

export default PremiumInfo;
