import { useLocation } from "react-router-dom";
import CheckPNG from "./assets/green_check_tiny.png";

const ConfirmationYes = () => {
    let { state } = useLocation();
    const policy_id  = state.policy_id;
    const premium = state.premium;
    var d = new Date();
    const curr_date = d.toLocaleDateString();

    return (
        <div className="flap-confirmation">
            <div className="confirm-header-container">
                <span>
                <img className="imgtxt" src={CheckPNG} alt="check" />
                <div className="text-large">You have successfully enrolled!</div>
                </span>
            </div>

            <div className="text-detail-box">
                <div className="text-detail">Policy Number: {policy_id} </div>
                <div className="text-detail">Monthly Payment: ${premium} </div>
                <div className="text-detail">Effective Date: {curr_date}</div>
            </div>
            <div className="text-bottom-box">
            <div className="text-medium">
                A confirmation email has been sent to you. If you have any questions about your policy, please find our <a href="https://www.flapinsurance.com/loan-coi" target="_blank">FAQ</a> or contact us via email at support@flapinsurance.com
            </div>
            </div>
        </div>
    );
};

export default ConfirmationYes;